import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "./Store/Store";
import { FaStar, FaSave, FaPencilAlt, FaTimes, FaTrash } from "react-icons/fa";
import StarRating from "./assets/js/StarRating";
import yaml from "js-yaml";
import "./assets/css/FilesList.css";

export default function FilesList() {
  const { token: contextToken, files, fetchFiles } = useAppContext();
  const [token, setToken] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [rating, setRating] = useState(0);
  const [hideSentiments, setHideSentiments] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (contextToken) {
      setToken(contextToken);
      sessionStorage.setItem("token", contextToken);
    } else if (storedToken) {
      setToken(storedToken);
    }
  }, [contextToken]);

  useEffect(() => {
    if (token) {
      fetchFiles(token);
    }
  }, [token]);

  const handleEditClick = (file) => {
    setEditingCommentId(file.id);
    setEditingText(file.text);
    setRating(file.rating);
  };

  const handleEditChange = (e) => {
    setEditingText(e.target.value);
  };

  const handleEditSubmit = async (fileId) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(`/api/edit_comment/${fileId}`, {
        text: editingText,
        rating: rating,
      });
      if (response.status === 200) {
        await fetchFiles(token);
        setEditingCommentId(null);
        setEditingText("");
      }
    } catch (error) {
      console.error("Error editing comment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = async (fileId) => {
    await axios.delete(`/api/delete/${fileId}?token=${token}`);
    fetchFiles(token);
  };

  const getHighlightedText = (text, insightsYaml) => {
    let highlightedText = text;
    try {
      let insights = yaml.load(insightsYaml);
      const themes = insights.reviews
        ? insights.reviews.flatMap((review) => review.themes || [])
        : [];
      const sortedThemes = sortAreasAndThemes(themes);
      sortedThemes.forEach((theme) => {
        if (theme.portions) {
          theme.portions.forEach((portion) => {
            const portionRegex = portion.portion
              .split("")
              .map((char) => {
                return char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
              })
              .join("\\s*");
            const regex = new RegExp(`(${portionRegex})`, "gs");
            const areaColor = selectedAreas.includes(
              theme.generic_area.split("/")[0]
            )
              ? areaColors[theme.generic_area.split("/")[0]]
              : "transparent";
            highlightedText = highlightedText.replace(
              regex,
              `<span style="background-color: ${areaColor}; border-bottom: ${hideSentiments
                ? "none"
                : portion.sentiment === "Positive"
                  ? "3px solid green"
                  : portion.sentiment === "Negative"
                    ? "3px solid red"
                    : "3px solid gray"
              };">$1</span>`
            );
          });
        }
      });
    } catch (e) { }
    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };

  const toggleHideSentiments = () => {
    setHideSentiments(!hideSentiments);
  };

  const getUniqueAreas = (insightsYaml) => {
    try {
      let insights = yaml.load(insightsYaml);
      const themes = insights.reviews
        ? insights.reviews.flatMap((review) => review.themes || [])
        : [];
      const uniqueAreas = [
        ...new Set(themes.map((theme) => theme.generic_area.split("/")[0])),
      ];
      return uniqueAreas;
    } catch (e) {
      return [];
    }
  };

  const generateAreaColors = (areas) => {
    const colors = [
      "#FFDDC1",
      "#FFE4E1",
      "#FFFACD",
      "#E0FFFF",
      "#D8BFD8",
      "#E6E6FA",
      "#FFF0F5",
      "#F0FFF0",
      "#F5F5DC",
      "#FAF0E6",
    ];
    const areaColors = {};
    let colorIndex = 0;
    areas.forEach((area) => {
      if (!areaColors[area]) {
        areaColors[area] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });
    return areaColors;
  };

  const uniqueAreas = [
    ...new Set(files.flatMap((file) => getUniqueAreas(file.insights))),
  ];

  const areaColors = generateAreaColors(uniqueAreas);

  const toggleAreaHighlight = (area) => {
    if (selectedAreas.includes(area)) {
      setSelectedAreas(
        selectedAreas.filter((selectedArea) => selectedArea !== area)
      );
    } else {
      setSelectedAreas([...selectedAreas, area]);
    }
  };

  const clearAllSelections = () => {
    setSelectedAreas([]);
  };

  const sortAreasAndThemes = (themes) => {
    const themesByArea = themes.reduce((acc, theme) => {
      const area = theme.generic_area.split("/")[0];
      if (!acc[area]) {
        acc[area] = [];
      }
      acc[area].push(theme);
      return acc;
    }, {});
    const sortedAreas = Object.keys(themesByArea).sort((a, b) =>
      a.localeCompare(b)
    );
    sortedAreas.forEach((area) => {
      themesByArea[area].sort((a, b) => a.theme.localeCompare(b.theme));
    });
    return sortedAreas.flatMap((area) => themesByArea[area]);
  };

  const renderTags = (tags, sentiment) => {
    let color;
    switch (sentiment) {
      case "Positive":
        color = "lightgreen";
        break;
      case "Negative":
        color = "lightcoral";
        break;
      case "Neutral":
        color = "lightgray";
        break;
      default:
        color = "transparent";
    }
    if (tags === null) {
      return <span></span>;
    }
    return tags.map((tag, index) => (
      <span key={index} className="tag" style={{ backgroundColor: color }}>
        {tag}
      </span>
    ));
  };

  return (
    <div style={{ width: '100%', overflowY: 'auto', display: 'flex', justifyContent: 'center'}}>
      <div className="file-list-container">
        {files.length === 0 ? (
          <div className="no-files">
            <a href="https://app.insightgpt.it" target="_blank" rel="noopener noreferrer" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="/insightgpt_logo.png"
                alt="InsightGPT Logo"
                style={{ height: '10vh', width: 'auto', display: 'block' }}
              />
            </a>
            <h2>No Files Found</h2>
          </div>
        ) : (
          <ul className="file-list">
            <a href="https://app.insightgpt.it" target="_blank" rel="noopener noreferrer" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="/insightgpt_logo.png"
                alt="InsightGPT Logo"
                style={{ height: '10vh', width: 'auto', display: 'block' }}
              />
            </a>
            {files.map((file) => (
              <li key={file.id} className="file-item">
                <div className="file-info">
                  <div className="file-header">
                    <h5 className="filename">
                      {file.title || file.displayFilename}&nbsp;
                      {[1, 2, 3, 4, 5].map((star) => (
                        <FaStar
                          key={star}
                          size={20}
                          color={star <= file.rating ? "#FFD700" : "#e4e5e9"}
                        />
                      ))}
                    </h5>
                    <div className="button-container">
                      {editingCommentId === file.id ? (
                        <button
                          onClick={() => handleEditSubmit(file.id)}
                          className="save-edit-button small-button"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <div className="spinner"></div>
                          ) : (
                            <>
                              <FaSave />
                              &nbsp;Save
                            </>
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleEditClick(file)}
                          className="edit-button small-button"
                        >
                          <FaPencilAlt />
                          &nbsp;Edit
                        </button>
                      )}
                      <button
                        onClick={() => handleFileDelete(file.id)}
                        className="delete-button small-button"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                  <small className="date">
                    {new Date(file.created_at).toLocaleString()}
                  </small>
                  <div className="text">
                    {editingCommentId === file.id ? (
                      <>
                        <textarea
                          value={editingText}
                          onChange={handleEditChange}
                          className="edit-textarea"
                        />
                        <StarRating rating={rating} setRating={setRating} />
                      </>
                    ) : (
                      getHighlightedText(file.text, file.insights)
                    )}
                  </div>
                  <button
                    className="hide-sentiments"
                    onClick={toggleHideSentiments}
                    style={{
                      backgroundColor: "#ddd",
                      borderRadius: "15px",
                      margin: "5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      border: "1px solid gray",
                      width: "150px",
                    }}
                  >
                    {hideSentiments ? "Show Perception" : "Hide Perception"}
                  </button>
                  <div className="area-tags">
                    {getUniqueAreas(file.insights).map((area) => (
                      <button
                        key={area}
                        className={`area-tag ${selectedAreas.includes(area) ? "selected" : ""
                          }`}
                        style={{
                          backgroundColor: areaColors[area],
                          borderRadius: "15px",
                          margin: "5px",
                          padding: "5px 10px",
                          cursor: "pointer",
                          transform: selectedAreas.includes(area)
                            ? "translateY(-5px)"
                            : "translateY(0)",
                          transition: "transform 0.2s",
                          border: "1px solid gray",
                        }}
                        onClick={() => toggleAreaHighlight(area)}
                      >
                        {area}
                      </button>
                    ))}
                    {selectedAreas.length > 0 && (
                      <button
                        className="clear-selection"
                        onClick={clearAllSelections}
                        style={{
                          backgroundColor: "#ddd",
                          borderRadius: "15px",
                          margin: "5px",
                          padding: "5px 10px",
                          cursor: "pointer",
                          border: "1px solid gray",
                        }}
                      >
                        Clear All <FaTimes />
                      </button>
                    )}
                  </div>
                  <div className="insights">
                    {yaml.load(file.insights)?.reviews?.map((review, index) => (
                      <div key={index} className="review">
                        {sortAreasAndThemes(review.themes || []).map(
                          (theme, themeIndex) => (
                            <div
                              key={themeIndex}
                              className="theme"
                              style={{
                                backgroundColor:
                                  areaColors[theme.generic_area.split("/")[0]],
                                borderColor:
                                  areaColors[theme.generic_area.split("/")[0]],
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="theme-header">
                                <h6>{theme.theme}</h6>
                                <span className="theme-area">
                                  {theme.generic_area}
                                </span>
                              </div>
                              <div className="theme-portions">
                                {theme.portions?.map((portion, portionIndex) => (
                                  <div key={portionIndex} className="portion">
                                    <p>{portion.portion}</p>
                                    <div className="tags">
                                      {renderTags(
                                        portion.positive_tags,
                                        "Positive"
                                      )}
                                      {renderTags(
                                        portion.negative_tags,
                                        "Negative"
                                      )}
                                      {renderTags(
                                        portion.neutral_tags,
                                        "Neutral"
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {file.type !== "text" && (
                  <audio
                    controls
                    src={`/audio_files/${file.token}/${file.filename}`}
                    className="audio-player"
                  ></audio>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
